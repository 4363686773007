import { Component, OnInit } from '@angular/core';
import { SharedService } from './../shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-left-history-panel',
  templateUrl: './left-history-panel.component.html',
  styleUrls: ['./left-history-panel.component.scss'],
})
export class LeftHistoryPanelComponent implements OnInit {
  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';

  clickEventSubscription: Subscription;
  showHistoryMenu: boolean = false;
  showLeftPanel: boolean = false;

  rootData: any = {};
  menuList: any = [];
  userId: string;
  userData: any = {};
  freeTrial: boolean = true;
  freeTrialDay: number = 0;
  selected: any = {};
  planType: string = '';
  planTypeFlag: boolean = true;

  constructor(
    private sharedservice: SharedService,
    public userService: UserService,
    private router: Router
  ) {
    this.clickEventSubscription = this.sharedservice
      .getClickEvent()
      .subscribe(() => {
        this.showLeftSection();
      });

    this.planType = localStorage.getItem('plan_name');
    if (this.planType == 'Legit™ Lite') {
      this.planTypeFlag = false;
    }
    if (this.planType == 'Legit™ Professional') {
      this.planTypeFlag = false;
    }
    if (this.planType == 'Free Trial') {
      this.planTypeFlag = false;
    }
  }

  ngOnInit(): void {
    if (
      localStorage.getItem('userId') &&
      localStorage.getItem('access_token')
    ) {
      this.getStorage();
      this.getUserDetails();
      if (
        localStorage.getItem('email') == 'authentificationtrial@tqllc.company'
      ) {
        this.showHistoryMenu = true;
      }
    }

    this.userService.isLoggedIn$.subscribe((isloggedIn) => {
      if (isloggedIn) {
        this.getStorage();
        this.getUserHistory();
        this.applyFreeTrial();
      }
    });

    this.userService.updateHistory$.subscribe((updatedHistory) => {
      if (updatedHistory) {
        this.getUserHistory();
      }
    });
  }

  getStorage() {
    this.userId = localStorage.getItem('userId');
    this.userData.name = localStorage.getItem('userName');
    this.userData.email = localStorage.getItem('email');
    this.userData.profileImage = localStorage.getItem('profile_pic');
    if (
      this.userData.profileImage &&
      !this.userData.profileImage.includes('googleusercontent.com')
    ) {
      this.userData.profileImage =
        'https://api.tqllc.company/' + this.userData.profileImage;
    }
    this.userData.access_token = localStorage.getItem('access_token');
  }

  getUserDetails(): void {
    this.userService.getUserDetails(this.userId).subscribe(
      (Response: any) => {
        if (Response.meta.code == 200) {
          this.getUserHistory();
        } else {
          this.openToaster(false, true, 'Unauthorized Access.');
          this.router.navigate(['/unauthorized']);
        }
      },
      (error: any) => {
        if (error?.error?.error?.code == 1021) {
          this.openToaster(
            false,
            true,
            'Your Free Trial is completed. Please purchase a plan to continue.'
          );
          this.router.navigate(['/expired']);
        } else if (error?.error?.error?.code == 1022) {
          this.openToaster(
            false,
            true,
            'Your Subscription is completed. Please purchase a plan to continue.'
          );
          this.router.navigate(['/plan-expired']);
        } else {
          this.openToaster(false, true, 'Unauthorized Access.');
          this.router.navigate(['/unauthorized']);
        }
      }
    );
  }

  getUserHistory(): void {
    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');
    console.log(this.planTypeFlag);
    if (this.planTypeFlag) {
      this.userService.getUserDocumentsByUser(userId, access_token).subscribe(
        (Response: any) => {
          if (Response.meta.files && Response.meta.files.length > 0) {
            this.menuList = Response.meta.files.filter(
              (file: any) =>
                file.status !== 'inactive' && file.status !== 'draft'
            );

            // Transform the list to include only `label` and `route` with previewText
            this.menuList = this.menuList.map((file: any) => ({
              _id: file._id,
              label: file.previewText,
              route: file.previewText,
              showActions: false,
              hideDelete: false,
            }));
            // setTimeout(() => {
            // 	this.menuList = Response.meta.files.filter((file: any) => file.status !== 'inactive' && file.status !== 'draft');
            // }, 1000);
          } else if (Response.meta.code == 1006) {
            console.log('No Documents Found');
            this.openToaster(false, true, 'No Documents Found');
            this.menuList = [];
          } else {
            this.menuList = [];
            this.openToaster(
              false,
              true,
              'Failed to retrieve user history data.'
            );
          }
        },
        (error: any) => {
          this.openToaster(
            false,
            true,
            'Failed to retrieve user history data.'
          );
          console.log(error);
        }
      );
    }

    if (!this.planTypeFlag) {
      this.userService
        .getHistory(this.userId, this.userData.access_token)
        .subscribe(
          (Response: any) => {
            if (Response.meta.code == 200) {
              console.log(Response.data);
              let searchKeys = new Set(
                Response.data.map((h: any) => h.searchKey)
              );
              // this.freeTrialDayCount = 3 - (Array.from(searchKeys).length == 0 ? 0 : Array.from(searchKeys).length);
              this.menuList = Array.from(searchKeys).map((h: any) => {
                return { label: h, route: h };
              });

              for (let list in this.menuList) {
                this.menuList[list].showActions = false;
                this.menuList[list].hideDelete = false;
              }

              console.log('History left: ' + JSON.stringify(this.menuList));
            } else if (Response.meta.code == 1006) {
              console.log('No History Found');
            } else {
              this.openToaster(
                false,
                true,
                'Failed to retrieve user history data.'
              );
            }
          },
          (error: any) => {
            this.openToaster(
              false,
              true,
              'Failed to retrieve user history data.'
            );
            console.log(error);
          }
        );
    }
    console.log(this.menuList);
  }

  applyFreeTrial() {
    this.freeTrial =
      localStorage.getItem('isfreetrial') == 'true' ? true : false;

    if (this.freeTrial) {
      this.calculateFreeDaysLeft();
    }
  }

  calculateFreeDaysLeft() {
    let subscription_details = JSON.parse(
      localStorage.getItem('subscription_details')
    );
    const date1 = new Date(subscription_details.createdAt);
    const date2 = new Date();
    const timeDifferenceMs = date2.getTime() - date1.getTime();
    const daysDifference =
      Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24)) + 1;
    if (daysDifference <= 15) {
      this.freeTrialDay = 15 - daysDifference;
    }
  }

  showHistory() {
    this.showHistoryMenu = !this.showHistoryMenu;
  }

  deleteHistoryList(index: number, event: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.menuList[index].showActions = !this.menuList[index].showActions;
    this.menuList[index].hideDelete = !this.menuList[index].hideDelete;
    console.log(this.menuList);
  }

  showLeftSection() {
    this.showLeftPanel = !this.showLeftPanel;
  }

  hideLefthistory() {
    this.showLeftPanel = false;
    console.log('hide btn clicked');
  }

  select(type, item, $event, mode) {
    this.selected[type] = this.selected[type] === item ? null : item;
    $event ? $event.stopPropagation() : null;
  }

  isActive(type, item) {
    return this.selected[type] === item;
  }

  deleteFile(doc: any) {
    console.log('deleted');

    let userId = localStorage.getItem('userId');
    let access_token = localStorage.getItem('access_token');

    this.userService
      .deleteUserDocument(userId, access_token, doc._id)
      .subscribe(
        (Response: any) => {
          this.getUserHistory();

          this.openToaster(
            true,
            false,
            'History document deleted successfully.'
          );
        },
        (error: any) => {
          console.log(error);
          this.openToaster(
            false,
            true,
            'Failed to delete user history document.'
          );
        }
      );
  }

  removeMenuList(menuItem: string, index: number, event: MouseEvent) {
    console.log(index);
    let returnQuery =
      index != 0
        ? this.menuList[0].label
        : this.menuList[1].label
        ? this.menuList[1].label
        : '';
    console.log(returnQuery);
    let userId = localStorage.getItem('userId');
    let token = localStorage.getItem('access_token');
    this.userService.removeMenuHistory(userId, token, menuItem).subscribe(
      (Response: any) => {
        if (Response.meta.code == 200) {
          this.openToaster(
            true,
            false,
            'Deleted Selected History Successfully.'
          );
          setTimeout(() => {
            if (returnQuery)
              this.router.navigate(['/query'], {
                queryParams: { search: returnQuery },
              });
            else this.router.navigate(['/']);
          }, 1000);
        } else {
          // this.router.navigate(['/unauthorized']);
          this.openToaster(false, true, 'Failed to Delete History Menu.');
        }
      },
      (error: any) => {
        // this.router.navigate(['/unauthorized']);
        this.openToaster(false, true, 'Failed to Delete History Menu.');
        console.log(error);
      }
    );
    event.stopPropagation();
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      this.closeToaster();
    }, 5000);
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }
}
