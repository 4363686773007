<!-- Mat loader starts -->
<div class="page_loader" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
  <!-- <div class="text-loader">
        <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
        <div class="word"></div>
    </div> -->
</div>
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container" style="font-family: 'Montserrat-regular', Arial, Helvetica, sans-serif;">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->

<div class="cache_wrapper_section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="home-p-title">
          <h3>Cached Records - {{ total | number : "1.0-0" }}</h3>
          <hr />
        </div>
        <div class="table-filters">
          <div class="row">
            <!-- <div class="col-5">
                            <div class="input-group">
                                <span class="input-group-text" id="inputGroup-sizing-default">Search Assertion</span>
                                <input type="text" class="form-control" aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-default" #search1
                                    (change)="onSelected(search1.value, 'search1')">
                            </div>
                        </div> -->
            <div class="col-lg-12">
              <div class="input-group">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  >Search Assertion
                </span>
                <input
                  type="text"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                  #search
                  (change)="onSelected(search.value, 'search')"
                />
              </div>
              <button
                type="button"
                class="btn btn-primary submit-btn"
                (click)="getFilters()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <!-- Loader -->
        <div class="table-loader" *ngIf="isLoading">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table-data" *ngIf="!isLoading">
          <!-- Data Table -->
          <div class="scroll_table">
            <table class="table table-hover responsive-table">
              <thead>
                <tr>
                  <th scope="col" class="col-4 assertion">Assertions</th>
                  <th scope="col" class="col-1 prob">Prob</th>
                  <th scope="col" class="col-1 prob2">Prob1</th>
                  <th scope="col" class="col-1 create">Created By</th>
                  <th scope="col" class="col-1 create-d">Created Date</th>
                  <th scope="col" class="col-2 action">Actions</th>
                </tr>
              </thead>
              <tbody width="100%">
                <tr *ngFor="let asssertion of assertions">
                  <td width="25%">{{ asssertion.assertion_text }}</td>
                  <td width="8%">
                    {{
                      asssertion.prob
                        ? asssertion.prob.toFixed(2)
                        : "Not Available"
                    }}
                  </td>
                  <td width="10%">
                    {{ asssertion.prob1 ? asssertion.prob1 : "Not Available" }}
                  </td>
                  <td width="20%">
                    {{
                      asssertion?.user_details?.email
                        ? asssertion?.user_details?.email
                        : "No Data"
                    }}
                  </td>
                  <td width="12%">
                    {{ formatDate(asssertion.created_date_time) }}
                  </td>
                  <td width="25%">
                    <a
                      title="View Record"
                      data-bs-toggle="modal"
                      data-bs-target="#viewModal"
                      (click)="viewRecord(asssertion._id)"
                      ><img
                        src="../../assets/images/svg/view-icon.svg"
                        alt="view"
                    /></a>
                    <a
                      title="Update Record"
                      data-bs-toggle="modal"
                      data-bs-target="#editModal"
                      (click)="editRecord(asssertion._id)"
                      ><img
                        src="../../assets/images/svg/Edit_icon.svg"
                        alt="edit"
                    /></a>
                    <a
                      title="Delete Record"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      (click)="deleteRecord(asssertion._id)"
                      ><img
                        src="../../assets/images/svg/delete-icon.svg"
                        alt="delete"
                    /></a>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="6"
                    style="text-align: center"
                    *ngIf="!assertions.length"
                  >
                    No Data Found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Pagination -->
          <div
            style="float: left"
            class="pagination-sec"
            *ngIf="showPagination"
          >
            <!-- Dropdown for number of items per page -->
            <select
              class="form-select"
              #limitSelect
              (change)="
                changeItemsPerPage(limitSelect.value); $event.preventDefault()
              "
            >
              <option
                *ngFor="let limitOption of limitOptions"
                [value]="limitOption"
                [selected]="limitOption == limit"
              >
                {{ limitOption }}
              </option>
            </select>
            <span>Items per page</span>
          </div>
          <div style="float: right; margin-left: 10px; padding: 0px">
            <button
              class="btn btn-warning"
              style="float: right; font-size: 12px !important"
              (click)="downloadCSV()"
            >
              Download CSV
            </button>
          </div>
          <div class="table-pagination" *ngIf="showPagination">
            <ul class="pagination">
              <li
                class="page-item first-page"
                [ngClass]="{ disabled: pageStart === 0 }"
                *ngIf="pageStart != 0"
              >
                <a
                  class="page-link"
                  tabindex="-1"
                  aria-disabled="true"
                  (click)="firstPage()"
                  >First Page</a
                >
              </li>
              <li
                class="page-item prev"
                [ngClass]="{ disabled: pageStart === 0 }"
              >
                <a
                  class="page-link"
                  tabindex="-1"
                  aria-disabled="true"
                  (click)="previousPages(pageStart, pageEnd)"
                  >Previous</a
                >
              </li>
              <span *ngFor="let ind of pages; let i = index">
                <li
                  [class]="
                    currentPage == ind ? 'page-item active' : 'page-item'
                  "
                  *ngIf="i < pageLength"
                >
                  <a class="page-link" (click)="nextPage(ind)">{{
                    pageStart + i + 1
                  }}</a>
                </li>
              </span>
              <!-- <li class="page-item active"><a class="page-link" href="#">{{currentPage + 1}}</a></li> -->
              <li
                class="page-item next"
                [ngClass]="{ disabled: pageEnd >= noOfPages }"
              >
                <a class="page-link" (click)="nextPages(pageStart, pageEnd)"
                  >Next</a
                >
              </li>
              <li
                class="page-item last-page"
                [ngClass]="{ disabled: pageEnd >= noOfPages }"
                *ngIf="pageEnd != noOfPages"
              >
                <a class="page-link" (click)="lastPage()">Last Page</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- View Modal -->
<div
  class="modal fade"
  id="viewModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content" style="display: block">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">View Record</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="table-loader" *ngIf="popupLoader">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div *ngIf="!popupLoader">
          <div class="row" *ngIf="assertionsArr.length > 0">
            <label for="message-text" class="col-form-label"
              >Assertion List:</label
            >
            <hr />
            <div *ngFor="let assertion of assertionsArr" class="row">
              <div class="col-sm-12">
                <div class="col-12 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Assertion:&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                  <textarea
                    class="form-control"
                    id="assertion-text-view"
                    disabled
                    [value]="
                      assertion.assertion_text ? assertion.assertion_text : ''
                    "
                  ></textarea>
                </div>

                <div class="col-12 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1">
                    Engine Status:&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="assertion-status"
                    disabled
                    placeholder=""
                    [value]="
                      assertion.status ? sentenceCase(assertion.status) : 0
                    "
                  />
                </div>

                <div class="mb-2">
                  <label for="message-text" class="col-form-label"
                    >Google Answer:</label
                  >
                  <textarea
                    class="form-control"
                    id="google-answer-view"
                    disabled
                    [value]="googleAnswer"
                  ></textarea>
                </div>
                <div class="mb-2">
                  <label for="message-text" class="col-form-label"
                    >Comments:</label
                  >
                  <div
                    [innerHTML]="transform(assertion.comment)"
                    class="form-control"
                    id="comment-view"
                    disabled
                  ></div>
                </div>

                <div class="mb-2">
                  <label for="message-text" class="col-form-label">URLs:</label>
                  <textarea
                    class="form-control"
                    id="urls-view"
                    [value]="
                      assertion.topSupportingUrls
                        ? getJoinedUrls(assertion.topSupportingUrls)
                        : ''
                    "
                    disabled
                  ></textarea>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Probability:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view"
                    disabled
                    placeholder="0 - 100"
                    [value]="assertion.prob ? assertion.prob.toFixed(2) : 0"
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Logical Fallacy:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="logical-fallacy-view"
                    disabled
                    [value]="assertion.logicalFallacy ? 'True' : 'False'"
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Knowledge Domain:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view"
                    disabled
                    placeholder="Knowledge Domain"
                    [value]="
                      assertion.knowledgeDomain
                        ? assertion.knowledgeDomain
                        : 'General'
                    "
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Created By:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-createdby-name"
                    [value]="
                      assertion?.user_details?.name
                        ? assertion?.user_details?.name
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >CreatedBy Email:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-createdby-email"
                    [value]="
                      assertion?.user_details?.email
                        ? assertion?.user_details?.email
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Created Date:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-updatedby-date"
                    [value]="
                      assertion?.created_date_time
                        ? formatDate(assertion?.created_date_time)
                        : ''
                    "
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Probability 1:</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob1-view"
                    disabled
                    placeholder="0 - 100"
                    [value]="assertion.prob1 ? assertion.prob1 : 0"
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Fallacy Type:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="logical-fallacy-type-view"
                    disabled
                    [value]="
                      assertion.logicalFallacy ? assertion.fallacyType : ''
                    "
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Knowledge Sub Domain:</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob1-view"
                    disabled
                    placeholder="Knowledge Sub Domain"
                    [value]="
                      assertion.knowledgeSubDomain
                        ? assertion.knowledgeSubDomain
                        : 'General'
                    "
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Updated By:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-updatedby-name"
                    [value]="
                      assertion?.updatedBy?.name
                        ? assertion?.updatedBy?.name
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >UpdatedBy Email:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-updatedby-email"
                    [value]="
                      assertion?.updatedBy?.email
                        ? assertion?.updatedBy?.email
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Updated Date:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-updatedby-date"
                    [value]="
                      assertion?.updatedBy?.updatedDate
                        ? formatDate(assertion?.updatedBy?.updatedDate)
                        : ''
                    "
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <p
            *ngIf="assertionsArr.length == 0"
            class="m-4"
            style="text-align: center"
          >
            No Assertions Found
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div
  class="modal fade"
  id="editModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content" style="display: block">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Record</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeEditModal"
        ></button>
      </div>
      <div class="modal-body">
        <div class="table-loader" *ngIf="popupLoader">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div *ngIf="!popupLoader">
          <div class="row" *ngIf="assertionsArr.length > 0">
            <label for="message-text" class="col-form-label"
              >Assertion List:</label
            >
            <hr />
            <div *ngFor="let assertion of assertionsArr" class="row">
              <div class="col-sm-12">
                <div class="col-12 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Assertion:&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                  <textarea
                    class="form-control"
                    id="assertion-text-edit-{{ assertion._id }}"
                    placeholder="Enter Assertion"
                    [value]="
                      assertion.assertion_text ? assertion.assertion_text : ''
                    "
                    disabled
                  ></textarea>
                </div>

                <div class="col-12 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1">
                    Engine Status:&nbsp;&nbsp;&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="assertion-status"
                    disabled
                    placeholder=""
                    [value]="
                      assertion.status ? sentenceCase(assertion.status) : 0
                    "
                  />
                </div>

                <div class="mb-2">
                  <label for="message-text" class="col-form-label"
                    >Google Answer:</label
                  >
                  <textarea
                    class="form-control"
                    id="google-answer-edit-{{ assertion._id }}"
                    [value]="googleAnswerEdit"
                  ></textarea>
                </div>

                <div class="mb-2">
                  <label for="message-text" class="col-form-label"
                    >Comments:</label
                  >
                  <textarea
                    class="form-control"
                    id="comment-edit-{{ assertion._id }}"
                    [value]="assertion.comment"
                    (input)="onInputChange(assertion._id)"
                  ></textarea>
                </div>
                <div class="mb-2">
                  <label for="message-text" class="col-form-label">URLs:</label>
                  <textarea
                    class="form-control"
                    id="urls-edit-{{ assertion._id }}"
                    [value]="
                      assertion.topSupportingUrls
                        ? getJoinedUrls(assertion.topSupportingUrls)
                        : ''
                    "
                  ></textarea>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Probability:&nbsp;&nbsp;</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="prob-edit-{{ assertion._id }}"
                    placeholder="0 - 100"
                    [value]="assertion.prob ? assertion.prob.toFixed(2) : 0"
                    disabled
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Logical Fallacy:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="logical-fallacy-edit-{{ assertion._id }}"
                    [value]="assertion.logicalFallacy ? 'True' : 'False'"
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Knowledge Domain:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="knowledge-domain-edit-{{ assertion._id }}"
                    placeholder="Knowledge Domain"
                    [value]="
                      assertion.knowledgeDomain
                        ? assertion.knowledgeDomain
                        : 'General'
                    "
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Created By:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-edit-createdby-name"
                    [value]="
                      assertion?.user_details?.name
                        ? assertion?.user_details?.name
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >CreatedBy Email:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-edit-createdby-email"
                    [value]="
                      assertion?.user_details?.email
                        ? assertion?.user_details?.email
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Created Date:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-view-updatedby-date"
                    [value]="
                      assertion?.created_date_time
                        ? formatDate(assertion?.created_date_time)
                        : ''
                    "
                    disabled
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Probability 1:</span
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="prob1-edit-{{ assertion._id }}"
                    placeholder="0 - 100"
                    [value]="assertion.prob1 ? assertion.prob1 : 0"
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Fallacy Type:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="logical-fallacy-type-edit-{{ assertion._id }}"
                    [value]="
                      assertion.logicalFallacy ? assertion.fallacyType : ''
                    "
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Knowledge Sub Domain:</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="knowledge-subdomain-edit-{{ assertion._id }}"
                    placeholder="Knowledge Sub Domain"
                    [value]="
                      assertion.knowledgeSubDomain
                        ? assertion.knowledgeSubDomain
                        : 'General'
                    "
                  />
                </div>

                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Updated By:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-edit-updatedby-name"
                    [value]="
                      assertion?.updatedBy?.name
                        ? assertion?.updatedBy?.name
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >UpdatedBy Email:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-edit-updatedby-email"
                    [value]="
                      assertion?.updatedBy?.email
                        ? assertion?.updatedBy?.email
                        : ''
                    "
                    disabled
                  />
                </div>
                <div class="col-6 input-group mb-2">
                  <span class="input-group-text" id="basic-addon1"
                    >Updated Date:&nbsp;&nbsp;</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="prob-edit-updatedby-date"
                    [value]="
                      assertion?.updatedBy?.updatedDate
                        ? formatDate(assertion?.updatedBy?.updatedDate)
                        : ''
                    "
                    disabled
                  />
                </div>
              </div>

              <div
                *ngIf="exceededLimit"
                style="color: red; margin-bottom: 10px"
              >
                Character limit 2000 has been exceeded for comment box!
              </div>
              <div class="col-12 mb-3">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-warning m-1"
                  (click)="updateAssertionRecord($event)"
                  value="{{ assertion._id }}"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <p
            *ngIf="assertionsArr.length == 0"
            class="m-4"
            style="text-align: center"
          >
            No Assertions Found
          </p>
        </div>
      </div>
      <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-warning">Update</button>
            </div> -->
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal"
  id="deleteModal"
  tabindex="-1"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content" style="display: block">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeDeleteModal"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure want to delete this record ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
