import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TutorialService, TutorialStep } from '../services/tutorial.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit, OnDestroy {
  @Input() stepContent: string = '';  // Add @Input() decorator
  @Input() stepTitle: string = '';    // Add @Input() decorator
  @Input() isVisible: boolean = false;  // Add @Input() decorator

  currentStepIndex: number = 0;
  totalSteps: number = 0;
  fullOverlay: boolean = false;
  highlightPosition: { top: number, left: number, width: number, height: number } | null = null;

  private tutorialVisibleSub: Subscription | null = null;
  private tutorialContentSub: Subscription | null = null;
  private tutorialTitleSub: Subscription | null = null;

  constructor(private tutorialService: TutorialService, private router: Router) {}

  ngOnInit() {
    console.log("TutorialComponent ngOnInit");
    // Subscribe to tutorial content, title, and visibility
    this.tutorialContentSub = this.tutorialService.tutorialContent$.subscribe(content => {
      this.stepContent = content;
    });

    this.tutorialTitleSub = this.tutorialService.tutorialTitle$.subscribe(title => {
      this.stepTitle = title;
    });


    this.tutorialService.tutorialHighlightType$.subscribe(highlightType => {
      if (highlightType == "99") {
        this.fullOverlay = true;        
      }
      else{
        this.fullOverlay = false;        
      }
    });
    

    this.tutorialService.tutorialVisible$.subscribe(isVisible => {
      this.isVisible = isVisible;  // When tutorialVisible$ is true, show the modal
      if (isVisible) {
        this.currentStepIndex = this.tutorialService.getCurrentStepIndex();
        this.totalSteps = this.tutorialService.getStepsCount();
      }
    });
  }

  toggleOverlay(isVisible: boolean) {
    const overlay = document.querySelector('.overlay');
    if (overlay) {
      overlay.classList.toggle('highlighted', isVisible);
    }
  }

  ngOnDestroy() {
    // Clean up subscriptions when component is destroyed to prevent memory leaks
    if (this.tutorialVisibleSub) this.tutorialVisibleSub.unsubscribe();
    if (this.tutorialContentSub) this.tutorialContentSub.unsubscribe();
    if (this.tutorialTitleSub) this.tutorialTitleSub.unsubscribe();
  }

  nextStep() {
    const next = this.tutorialService.nextStep();
    if (next) {
      this.updateTutorial(next);
    }
  }

  previousStep() {
    const previous = this.tutorialService.previousStep();
    if (previous) {
      this.updateTutorial(previous);
    }
  }

  closeTutorial() {
    this.tutorialService.closeModal();
    this.isVisible = false;
    this.router.navigate(['/']);
  }


  updateTutorial(step: TutorialStep) {
    this.stepContent = step.content;
    this.stepTitle = step.title;
    this.currentStepIndex = this.tutorialService.getCurrentStepIndex();
    this.totalSteps = this.tutorialService.getStepsCount();
    this.setHighlightPosition(step.route);
  }

  setHighlightPosition(route: string | undefined) {
    if (!route) return;

    const selector = `.${route}`;
    const elements = document.querySelectorAll(selector);
    console.log('Elements:', elements);

    if (elements.length > 0) {
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        console.log('Element Rect:', rect);

        this.highlightPosition = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
          height: rect.height,
        };

        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.classList.add('highlight');

        setTimeout(() => {
          element.classList.remove('highlight');
        }, 3000);
      });
    } else {
      this.highlightPosition = null;
    }
  }
}
