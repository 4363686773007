<!-- Mat loader starts -->
<!-- <div class="page_loader" *ngIf="isLoadingOld">
    <mat-spinner></mat-spinner>
    <div class="text-loader">
        <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
        <div class="word"></div>
    </div>
</div> -->
<!-- Mat loader starts -->

<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Error</h3>
        <p>{{showErrorMsg}}</p>
    </div>
    <a  (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
    <div class="outer-container">
        <img src="../../assets/images/svg/success-toaster-icon.svg" alt="warning toaster">
    </div>
    <div class="inner-container">
        <h3>Success</h3>
        <p>{{showSuccessMsg}}</p>
    </div>
    <a (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
</div>
<!-- warning toast ends -->

<div class="main-search-results clearfix" [ngClass]="rightPanelAction ? 'expend-panel' : 'collapse-panel'">
    <div class="search-results-panel">

        <!-- Search category tabs -->
        <div class="search-category-tabs" (clickOutside)="closeDDownload()">
            <div class="row desktop-show">
                <div class="col-lg-12">
                    <div class="category-tabs">
                        <ul>
                            <li><a (click)="openDialog('welcome')">Welcome Message</a></li>
                            <li><a (click)="openDialog('parts_of_speech')">Parts of Speech</a></li>
                            <!-- <li><a  (click)="openDialog('general_definition')">General Definitions</a></li> -->
                            <!-- <li><a  (click)="openDialog('general_analysis')">General Analysis</a></li> -->
                            <!-- <li><a  (click)="openDialog('entities')">Entities</a></li> -->
                            <li><a (click)="openDialog('character_counter')">Character counter</a></li>
                             <li>
                                <a  class="d-submenu" (click)="downloadDOption()">Download</a>
                                <div class="download-menu" *ngIf="downloadOptions">
                                    <ul>
                                        <!-- <li><a (click)="downloadAsPDF()">Download PDF (.pdf)</a></li> -->
                                        <li><a (click)="downloadAsExcel()">Download Excel (.csv)</a></li>
                                      <!---- <li><a >Download word (.docx)</a></li> -->
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mobile-view-tabs" (clickOutside)="closeMDownload()">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="m-cate-tabs">
                            <div class="m-cate-title" [ngClass]="{'m-active' : showMCategory}">
                                <a  (click)="viewMTabs()">View More</a>
                            </div>
                            <div class="m-cate-menu" *ngIf="showMCategory">
                                <ul>
                                    <li><a (click)="openDialog('welcome')">Welcome Message</a></li>
                                    <li><a (click)="openDialog('parts_of_speech')">Parts of Speech</a></li>
                                    <!-- <li><a  (click)="openDialog('general_definition')">General Definitions</a>
                                    </li> -->
                                    <!-- <li><a  (click)="openDialog('general_analysis')">General Analysis</a></li> -->
                                    <!-- <li><a  (click)="openDialog('entities')">Entities</a></li> -->
                                    <li><a (click)="openDialog('character_counter')">Character counter</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <!-- <div class="m-downloads">
                            <div class="m-cate-title" [ngClass]="{'m-active' : downloadPew}">
                                <a  class="d-submenu" (click)="downloadMOption()">Download</a>
                            </div>
                            <div class="download-mobile-options" *ngIf="downloadPew">
                                <ul>
                                    <li><a  (click)="downloadAsPDF()">Download PDF (.pdf)</a></li>
                                    <li><a >Download Excel (.csv)</a></li>
                                    <li><a >Download word (.docx)</a></li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="display-content-section">
            <app-main-engine [searchResp]="searchResponse" [loading]="isLoading"></app-main-engine>
        </div>
    </div>

</div>