import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: ['./cache.component.scss'],
})
export class CacheComponent implements OnInit {
  // Loader & Error Handling Variables
  isLoading: boolean = false;
  showSuccessToaster: boolean = false;
  showSuccessMsg: string = '';
  showErrorToaster: boolean = false;
  showErrorMsg: string = '';

  popupLoader: boolean = false;
  assertions: any = [];
  offset: number = 0;
  limit: number = 10;
  limitOptions: number[] = [10, 25, 50, 100];
  total: number = 0;
  currentPage: number = 0;
  noOfPages: number = 0;
  pages: any[] = [];
  pageStart: number = 0;
  pageLength: number = 5;
  pageEnd: number = this.pageStart + this.pageLength;

  searchQuery: string = '';
  googleAnswer: string = '';
  googleAnswerId: string = '';
  googleAnswerEdit: string = '';
  assertionsArr: any = [];
  deleteId: string = '';
  exceededLimit: boolean = false;
  showPagination: boolean = true;
  env: string = environment.env;
  downloadPath: string = environment.tqllcUpload;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private sanitizer: DomSanitizer
  ) {
    let projectUsers = [
      'mohsinec317@gmail.com',
      'bujare@maxiomtech.com',
      'mohsin.u@turing.com',
      'jhnatio@jgpis.org',
      'johnhnatio@tqllc.company',
      'andrewmeiners@tqllc.company',
      'bbecker@sandybecker.com',
      'info@tqllc.company',
      'george.vernidub@maxiomtech.com',
      'antonio@maxiomtech.com',
      'bruce.h.becker@gmail.com',
      'support@tqllc.company',
      'bruce.h.becker@tqllc.company',
      'andrewameiners@gmail.com',
      'gaurav@maxiomtech.com',
    ];
    if (this.env != 'dev-portal') {
      this.router.navigate(['/unauthorized']);
      return;
    }

    if (!projectUsers.includes(localStorage.getItem('email'))) {
      this.router.navigate(['/unauthorized']);
      return;
    }
  }

  ngOnInit(): void {
    this.getAssertions();
  }

  downloadCSV() {
    this.getAssertions(true);
  }

  transform(value: string): SafeHtml {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const linkedText = value.replace(
      urlRegex,
      '<a href="$1" target="_blank">$1</a>'
    );
    return this.sanitizer.bypassSecurityTrustHtml(linkedText);
  }

  changeItemsPerPage(selectedValue: number) {
    console.log('Selected Limit: ', selectedValue);
    this.limit = Number(selectedValue) || selectedValue;
    this.offset = 0;
    this.pageStart = 0;
    this.pageEnd = this.pageLength;
    this.currentPage = 0;
    this.getAssertions();
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.offset = this.offset - this.limit;
      this.currentPage--;
      this.getAssertions();
    }
  }

  nextPage(index: number) {
    console.log('index', index);
    if (this.currentPage <= this.noOfPages) {
      this.offset = index * this.limit;
      console.log('new offset', this.offset);
      this.currentPage = index;
      this.getAssertions();
    }
  }

  nextPages(pageStart: number, pageEnd: number) {
    console.log('Before:', this.pageStart, this.pageEnd, this.currentPage);
    if (this.currentPage <= this.noOfPages) {
      this.pageEnd = pageEnd + this.pageLength;
      this.pageStart = pageStart + this.pageLength;
      this.currentPage = this.pageStart;
      this.offset = this.currentPage * this.limit;
      this.getAssertions();
    }
    console.log('after:', this.pageStart, this.pageEnd, this.currentPage);
  }

  previousPages(pageStart: number, pageEnd: number) {
    console.log('Before:', this.pageStart, this.pageEnd, this.currentPage);
    if (this.currentPage >= this.pageLength && this.currentPage >= 0) {
      this.pageEnd = pageEnd - this.pageLength;
      this.pageStart = pageStart - this.pageLength;
      this.currentPage = this.pageStart;
      this.offset = this.currentPage * this.limit;
      this.getAssertions();
    }
    console.log('after:', this.pageStart, this.pageEnd, this.currentPage);
  }

  lastPage() {
    this.pageEnd = this.noOfPages;
    this.pageStart = this.noOfPages - this.pageLength;
    this.currentPage = this.noOfPages - 1;
    this.offset = this.currentPage * this.limit;
    this.getAssertions();
  }

  firstPage() {
    this.pageEnd = this.pageLength;
    this.pageStart = 0;
    this.currentPage = 0;
    this.offset = this.currentPage * this.limit;
    this.getAssertions();
  }

  onSelected(value: string, key: string): void {
    if (key == 'search') {
      this.searchQuery = value;
    }
  }

  getFilters() {
    this.pageStart = 0;
    this.currentPage = 0;
    this.offset = this.currentPage * this.limit;
    this.getAssertions();
  }

  getAssertions(download: boolean = false): void {
    this.isLoading = true;
    this.userService
      .getAssertionList(this.offset, this.limit, this.searchQuery, download)
      .subscribe((Response: any) => {
        this.isLoading = false;
        if (Response.meta.code == 200 && download) {
          window
            .open(this.downloadPath + Response.data.file_path, '_blank')
            .focus();
        } else if (
          Response.meta.code == 200 &&
          Response.data.assertions.length > 0
        ) {
          this.assertions = Response.data.assertions;
          this.total = Response.data.totalRecords;
          this.noOfPages = Math.ceil(this.total / this.limit);
          if (this.total % this.total != 0) {
            this.noOfPages++;
          }

          this.pages = [];
          if (this.noOfPages < this.pageEnd) {
            this.pageEnd = this.noOfPages;
          }
          for (let i = this.pageStart; i < this.pageEnd; i++) {
            this.pages.push(i);
          }
          this.showPagination = true;
        } else {
          this.showPagination = true;
          this.assertions = [];
          this.total = Response.data.totalRecords
            ? Response.data.totalRecords
            : 0;
          this.noOfPages = 0;
          // this.openToaster(true, true, "Error", 'No Assertions Found');
        }
      });
  }

  getGoogleAnswer(id: any) {
    this.userService.getGoogleAnswer(id).subscribe((Response: any) => {
      this.popupLoader = false;
      if (Response.meta.code == 200 && Response.data.length > 0) {
        this.googleAnswerId = Response.data[0]._id;
        this.googleAnswer = Response.data[0].google_answer;
        this.googleAnswerEdit = Response.data[0].google_answer;
      } else {
        this.openToaster(false, true, 'No Assertions Found');
      }
    });
  }

  sentenceCase(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  deleteAssertion(id: any) {
    this.userService.deleteAssertion(id).subscribe((Response: any) => {
      console.log(Response);
      if (Response.meta.code == 200) {
        document.getElementById('closeDeleteModal').click();
        alert('Assertion Deleted Successfully');
        this.getAssertions();
      } else {
        this.openToaster(false, true, 'No Assertions Found');
      }
    });
  }

  formatDate(dd: any): any {
    var date = new Date(dd);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  onInputChange(id: any) {
    let comment = document.getElementById(
      'comment-edit-' + id
    ) as HTMLInputElement;

    // Check if the comment input exceeds the character limit
    this.exceededLimit = comment?.value?.length >= 2000;
  }

  viewRecord(tarVal: any) {
    this.googleAnswerId = '';
    this.googleAnswer = '';
    this.assertionsArr = [];
    if (tarVal) {
      this.popupLoader = true;
      let selectedAssertion = this.assertions.find((ass) => ass._id === tarVal);
      selectedAssertion.comment = selectedAssertion.comment
        ? selectedAssertion.comment
        : '';
      if (selectedAssertion.googleAnswId)
        this.getGoogleAnswer(selectedAssertion.googleAnswId);
      else {
        this.googleAnswerId = '';
        this.googleAnswer = '';
        this.googleAnswerEdit = '';
        this.popupLoader = false;
      }
      this.assertionsArr.push(selectedAssertion);
    } else {
      this.openToaster(false, true, 'No Assertion ID Found for this Record');
    }
  }

  editRecord(tarVal: any) {
    this.googleAnswerEdit = '';
    this.googleAnswerId = '';
    this.assertionsArr = [];
    if (tarVal) {
      this.popupLoader = true;
      let selectedAssertion = this.assertions.find((ass) => ass._id === tarVal);
      selectedAssertion.comment = selectedAssertion.comment
        ? selectedAssertion.comment
        : '';
      if (selectedAssertion.googleAnswId)
        this.getGoogleAnswer(selectedAssertion.googleAnswId);
      else {
        this.googleAnswerId = '';
        this.googleAnswer = '';
        this.googleAnswerEdit = '';
        this.popupLoader = false;
      }
      this.assertionsArr.push(selectedAssertion);
      console.log(this.assertionsArr);
    } else {
      this.openToaster(false, true, 'No Assertion ID Found for this Record');
    }
  }

  deleteRecord(tarVal: any) {
    this.popupLoader = false;
    this.deleteId = '';
    if (tarVal) {
      this.deleteId = tarVal;
    } else {
      this.openToaster(false, true, 'No Assertion ID Found for this Record');
    }
  }

  confirmDelete() {
    this.deleteAssertion(this.deleteId);
  }

  updateAssertionRecord(event: any) {
    if (this.exceededLimit) {
      this.openToaster(false, true, 'Comment cannot exceed 2000 characters');
      return;
    }

    if (event.target.value) {
      // let assertion_text = document.getElementById('assertion-text-edit-' + event.target.value) as HTMLInputElement;
      // let probability = document.getElementById('prob-edit-' + event.target.value) as HTMLInputElement;
      let probability1 = document.getElementById(
        'prob1-edit-' + event.target.value
      ) as HTMLInputElement;
      let comment = document.getElementById(
        'comment-edit-' + event.target.value
      ) as HTMLInputElement;
      let urls = document.getElementById(
        'urls-edit-' + event.target.value
      ) as HTMLInputElement;
      let googleAnswerNew = document.getElementById(
        'google-answer-edit-' + event.target.value
      ) as HTMLInputElement;

      let knowledgeDomain = document.getElementById(
        'knowledge-domain-edit-' + event.target.value
      ) as HTMLInputElement;

      let knowledgeSubDomain = document.getElementById(
        'knowledge-subdomain-edit-' + event.target.value
      ) as HTMLInputElement;

      let logicalFallacy = document.getElementById(
        'logical-fallacy-edit-' + event.target.value
      ) as HTMLInputElement;

      let logicalFallacyType = document.getElementById(
        'logical-fallacy-type-edit-' + event.target.value
      ) as HTMLInputElement;

      let uptDateGoogle = {
        google_answer: googleAnswerNew.value ? googleAnswerNew.value : '',
      };
      let uptDate = {
        // assertion_text: assertion_text.value,
        // prob: parseInt(probability.value) || probability.value,
        prob1: parseInt(probability1.value) || probability1.value,
        comment: comment.value ? comment.value : '',
        urls: urls.value ? urls.value : '',
        updatedBy: {
          email: localStorage.getItem('email')
            ? localStorage.getItem('email')
            : '',
          name: localStorage.getItem('userName')
            ? localStorage.getItem('userName')
            : '',
          id: localStorage.getItem('userId')
            ? localStorage.getItem('userId')
            : '',
          updatedDate: new Date(),
        },
        knowledgeDomain: knowledgeDomain.value ? knowledgeDomain.value : '',
        knowledgeSubDomain: knowledgeSubDomain.value
          ? knowledgeSubDomain.value
          : '',
        logicalFallacy: logicalFallacy.value ? logicalFallacy.value : '',
        fallacyType: logicalFallacyType.value ? logicalFallacyType.value : '',
      };
      this.userService
        .updateAssertion(event.target.value, uptDate)
        .subscribe((Response: any) => {
          if (
            Response.meta.code == 200 &&
            Response.meta.message == 'Success.'
          ) {
            console.log(this.googleAnswerId);
            if (this.googleAnswerId && this.googleAnswerId != '') {
              this.userService
                .updateGoogleAnswer(this.googleAnswerId, uptDateGoogle)
                .subscribe((Response: any) => {
                  if (
                    Response.meta.code == 200 &&
                    Response.meta.message == 'Success.'
                  ) {
                    document.getElementById('closeEditModal').click();
                    alert('Record Updated Successfully.');
                    this.getAssertions();
                  }
                });
            } else {
              this.userService
                .createGoogleAnswer(uptDateGoogle)
                .subscribe((Response: any) => {
                  if (
                    Response.meta.code == 200 &&
                    Response.meta.message == 'Success.'
                  ) {
                    let updId = {
                      googleAnswId: Response.data._id,
                    };
                    this.userService
                      .updateAssertion(event.target.value, updId)
                      .subscribe((Response: any) => {
                        document.getElementById('closeEditModal').click();
                        alert('Record Updated Successfully.');
                        this.getAssertions();
                      });
                  }
                });
            }
          } else {
            this.openToaster(false, true, 'Update Operation Failed');
          }
        });
    } else {
      this.openToaster(false, true, 'No Assertion ID Found for this Record');
    }
  }

  openToaster(isSuccess: boolean, isError: boolean, message: string) {
    if (isSuccess) {
      this.showSuccessToaster = true;
      this.showSuccessMsg = message;
    } else if (isError) {
      this.showErrorToaster = true;
      this.showErrorMsg = message;
    }

    setTimeout(() => {
      this.closeToaster();
    }, 10000);
  }

  closeToaster() {
    this.showErrorToaster = false;
    this.showErrorMsg = '';
    this.showSuccessToaster = false;
    this.showSuccessMsg = '';
  }
  getJoinedUrls(topSupportingURLs = []) {
    return topSupportingURLs.map((urlObj) => urlObj.website_url).join('\n');
  }
}
