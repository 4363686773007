<!-- warning toast starts -->
<div class="toast error static-toaster" *ngIf="showErrorToaster">
  <div class="outer-container">
    <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster" />
  </div>
  <div class="inner-container">
    <h3>Error</h3>
    <p>{{ showErrorMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<div class="toast success static-toaster" *ngIf="showSuccessToaster">
  <div class="outer-container">
    <img
      src="../../assets/images/svg/success-toaster-icon.svg"
      alt="warning toaster"
    />
  </div>
  <div class="inner-container" style="font-family: 'Montserrat-regular', Arial, Helvetica, sans-serif;">
    <h3>Success</h3>
    <p>{{ showSuccessMsg }}</p>
  </div>
  <a (click)="closeToaster()"
    ><img src="../../assets/images/svg/Cancel.svg" alt="close alert"
  /></a>
</div>
<!-- warning toast ends -->

<div
  class="header-section"
  [ngClass]="{ 'search-header': currentRoute == '/query' }"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-sm-6 search-result-header">
        <div class="h-left-section">
          <ul>
            <!-- *ngIf="currentRoute !== '/query'" -->
            <!-- <li><a href="https://truthquotient.co/" target="_blank"><img
                                    src="../../assets/images/tqllc-footer-logo.png" alt="footer tqllc logo" /></a></li> -->
            <li>
              <a href="https://truthquotient.co/" target="_blank"
                >Visit Website</a
              >
            </li>
            <li>
              <a href="https://truthquotient.co/resources" target="_blank"
                >Resources</a
              >
            </li>
          </ul>
          <div
            class="header-global-search"
            *ngIf="currentRoute !== '/query'"
            (clickOutside)="closeHeaderSearch()"
            [ngClass]="resizeSearch ? 'expend' : 'collapse'"
            style="display: none"
          >
            <div class="tqls-search">
              <div class="search-input" (click)="resizerSearchHeight()">
                <!-- <mat-form-field class="custom-mat-form-field"> -->
                <textarea
                  [rows]="rows"
                  #desktopSearch
                  class="main-page-input gb-search-ipt"
                  [value]="searchQuery"
                  (input)="onChange($event, 'search_query')"
                  autocomplete="off"
                  [placeholder]="placeholderText"
                  spellcheck="true"
                  name="searchQuery"
                  (clickOutside)="onOutsideClick()"
                  [formControl]="email"
                  (keydown)="preventEnterKey($event)"
                  id="hsearch"
                ></textarea>
                <!-- </mat-form-field> -->
                <div class="delete-query" *ngIf="searchQuery">
                  <a (click)="removeSearch()">
                    <img
                      src="../../assets/images/svg/close-icon.svg"
                      alt="close search"
                    />
                  </a>
                </div>
              </div>
              <div class="main_auto_suggition">
                <div
                  class="auto-suggitions header"
                  *ngIf="searchSuggestions.length > 0 && showSuggestions"
                >
                  <div class="auto-suggest-scroll">
                    <ul>
                      <li *ngFor="let suggestion of searchSuggestions">
                        <div class="suggest-item clearfix">
                          <!-- <div class="suggested-score g-border">90</div> -->
                          <div
                            class="suggested-text"
                            (click)="setSearchQuery(suggestion.assertion_text)"
                          >
                            <span class="highlighted">{{
                              suggestion.assertion_text
                            }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="search-v-c" *ngIf="currentRoute !== '/'">
              <ul>
                <!-- <li><a  class="voice">
                                        <img src="../../assets/images/svg/voice-icon.svg" alt="voice search" />
                                    </a></li>
                                <li><a  class="copy">
                                        <img src="../../assets/images/svg/copy-icon.svg" alt="copy search" />
                                    </a></li> -->
                <li>
                  <a class="search" (click)="createAnalysis()">
                    <img
                      src="../../assets/images/svg/search-icon.svg"
                      alt="search result"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Mobile menu -->
        <div
          class="mobile-menu"
          (clickOutside)="closeMmenu()"
          *ngIf="currentRoute !== '/query'"
        >
          <a class="mobile-menu-icon" (click)="showMobileMenu()">
            <img src="../../../assets/images/svg/menu-icon.svg" alt="menu icon"
          /></a>
          <div
            class="user-profile-dropdown"
            [ngClass]="mobileMenu ? 'show' : 'hide'"
          >
            <ul>
              <li>
                <a
                  href="https://truthquotient.co/"
                  target="_blank"
                  class="website"
                  >Visit website</a
                >
              </li>
              <li>
                <a
                  href="https://truthquotient.co/resources"
                  target="_blank"
                  class="resource"
                  >Resources</a
                >
              </li>
              <!-- <li><a  class="tutorial">Tutorial</a></li> -->
              <li>
                <a class="feedback" (click)="openDialog('feedback')"
                  >Feedback</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 home-page-logo">
        <div class="header-logo">
          <!-- <a href="https://truthquotient.co/" target="_blank" class="default-logo"><img src="../../../assets/images/authentification-logo.png" alt="header logo" /></a> -->
          <!-- <a href="https://truthquotient.co/" target="_blank" class="dynamic-logo"><img src="../../../assets/images/legit-logo.png" alt="header logo" /></a> -->
          <!-- <a *ngIf="planType == 'Legit™ Lite' && !planTypeFlag" href="https://truthquotient.co/" target="_blank"
                        class="dynamic-logo"><img src="../../../assets/images/legit-logo-lite.PNG" alt="m-logo" /></a>
                    <a *ngIf="planType == 'Legit™ Professional' && !planTypeFlag" href="https://truthquotient.co/" target="_blank"
                        class="dynamic-logo"><img src="../../../assets/images/legit-logo-professional.png"
                            alt="m-logo" /></a>
                    <a *ngIf="planType == 'Legit™ Premium' || planTypeFlag" href="https://truthquotient.co/" target="_blank"
                        class="dynamic-logo"><img src="/assets/images/legit-logo-premium.png"
                            alt="m-logo" /></a> -->
                            <a *ngIf="logoUrl !== ''" href="https://truthquotient.co/" target="_blank" class="dynamic-logo">
                              <img src="{{ logoUrl }}" alt="m-logo" />
                            </a>
                            <!-- Optional: A placeholder until logoUrl is updated -->

        </div>
      </div>
      <div class="col-lg-4 col-sm-6 align-self-start">
        <div class="h-right-section">
          <ul>
                        
            <li class="hide-m" *ngIf="env === 'dev-portal'">
              <a>
                <mat-slide-toggle
                  title="Cache switch"
                  [checked]="isCachedStored === 'true'"
                  (change)="getNextData()"
                  color="primary"
                ></mat-slide-toggle>
              </a>
            </li>
            <li class="hide-m" *ngIf="env === 'dev-portal'">
              <a>
                <mat-slide-toggle
                  title="Demo switch"
                  [checked]="isDemoCheck === 'true'"
                  (change)="enableDemoData()"
                  color="primary"
                ></mat-slide-toggle>
              </a>
            </li>


            <li class="hide-m" *ngIf="planType === 'Legit™ Premium'" (click) = "tutorial()"><a >Tutorial</a></li>


            <li class="hide-m">
              <a (click)="openDialog('feedback')">Feedback</a>
            </li>
            <li class="user-profile" (clickOutside)="closedeskSettings()">
              <a (click)="userProfile()" [title]="userData.name">
                <img
                  [src]="userData.profileImage"
                  *ngIf="userData.profileImage"
                  alt="profile image"
                />
                <img
                  src="../../../assets/images/user.png"
                  *ngIf="!userData.profileImage"
                  alt="profile image"
                />
              </a>
              <div
                class="user-profile-dropdown"
                [ngClass]="userdeskSettings ? 'show' : 'hide'"
              >
                <ul>
                  <li>
                    <a
                      href="https://truthquotient.co/myAccount"
                      target="_blank"
                      class="account"
                      >My Account</a
                    >
                  </li>
                  <li><a class="logout" (click)="logout()">Logout</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="search-mobile-header" *ngIf="currentRoute == '/query'">
  <div class="top-header">
    <div class="history-menu-icon">
      <a (click)="leftMenuAction()"
        ><img src="../../../assets/images/svg/menu-icon.svg" alt="menu icon"
      /></a>
    </div>
    <div class="mobile-h-logo" (click)="openRootPage()">
      <img
        *ngIf="planType === 'Legit™ Lite'"
        src="../../../assets/images/legit-logo-lite.PNG"
        alt="m-logo"
      />
      <img
        *ngIf="planType === 'Free Trial'"
        src="../../../assets/images/legit-logo-professional.png"
        alt="m-logo"
      />
      <img
        *ngIf="planType === 'Legit™ Professional'"
        src="../../../assets/images/legit-logo-professional.png"
        alt="m-logo"
      />
      <img
        *ngIf="planType === 'Legit™ Premium'"
        src="assets/images/legit-logo-premium.png"
        alt="m-logo"
      />
      <img
        *ngIf="planType === 'Free Trial'"
        src="../../../assets/images/legit-logo-professional.png"
      />
    </div>
    <div class="h-right-section">
      <ul>
        <!-- <li class="hide-m"><a >Tutorial</a></li> -->
        <li class="hide-m"><a (click)="openDialog('feedback')">Feedback</a></li>
        <li class="user-profile" (clickOutside)="closeSettings()">
          <a (click)="userProfile()">
            <img
              [src]="userData.profileImage"
              *ngIf="userData.profileImage"
              alt="profile image"
            />
            <img
              src="../../../assets/images/user.png"
              *ngIf="!userData.profileImage"
              alt="profile image"
            />
          </a>
          <div
            class="user-profile-dropdown"
            [ngClass]="userSettings ? 'show' : 'hide'"
          >
            <ul>
              <li>
                <a
                  href="https://truthquotient.co/myAccount"
                  target="_blank"
                  class="account"
                  >My Account</a
                >
              </li>
              <li><a class="logout" (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="m-search-section">
    <div
      class="header-global-search"
      *ngIf="currentRoute == '/query'"
      (clickOutside)="closeMobileSearch()"
    >
      <div
        class="tqls-search"
        [ngClass]="resizeMSearch ? 'mexpend' : 'mcollapse'"
      >
        <div class="search-input" (click)="resizerMSearch()">
          <!-- <a  class="search">
                        <img src="../../assets/images/svg/search-icon.svg" alt="search result" />
                    </a> -->
          <textarea
            matInput
            autocomplete="off"
            [rows]="mrows"
            #mobileSearch
            (input)="onChange($event, 'search_query')"
            ng
            name="searchQuery"
            autocomplete="off"
            value="{{ searchQuery }}"
            spellcheck="true"
            (clickOutside)="onOutsideClick()"
            placeholder="Type something..."
            class="custom-textarea"
            [formControl]="msearch"
            (keydown)="preventEnterKey($event)"
          ></textarea>
          <div class="delete-query" *ngIf="searchQuery">
            <a (click)="removeSearch()">
              <img
                src="../../assets/images/svg/close-icon.svg"
                alt="close search"
              />
            </a>
          </div>
        </div>
        <div class="main_auto_suggition">
          <div class="auto-suggitions" *ngIf="searchSuggestions.length > 0">
            <div class="auto-suggest-scroll">
              <ul id="suggestionList-mobile">
                <li *ngFor="let suggestion of searchSuggestions">
                  <div class="suggest-item clearfix">
                    <div
                      class="suggested-text"
                      (click)="setSearchQuery(suggestion.assertion_text)"
                    >
                      <span class="highlighted">{{
                        suggestion.assertion_text
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="search-v-c" *ngIf="currentRoute !== '/'">
        <ul>
          <li>
            <!-- <a  (click)="createAnalysis()">
                            <img src="../../assets/images/svg/search-icon.svg" alt="search result" />
                        </a> -->
            <a (click)="createAnalysis()">
              <img
                src="../../assets/images/svg/search-icon.svg"
                alt="search icon"
              />
            </a>
          </li>
          <!-- <li><a  class="voice">
                            <img src="../../assets/images/svg/voice-icon.svg" alt="voice search" />
                        </a></li>
                    <li><a  class="copy">
                            <img src="../../assets/images/svg/copy-icon.svg" alt="copy search" />
                        </a></li> -->
        </ul>
      </div>
    </div>
  </div>
</div>
