<div class="new_search_results_section">
    <!-- Mat loader starts -->
    <!-- <div class="page_loader" *ngIf="isLoadingOld">
    <mat-spinner></mat-spinner>
    <div class="text-loader">
        <img src="../../assets/images/svg/logo-icon.svg" alt="logo icon" />
        <div class="word"></div>
    </div>
</div> -->
    <!-- Mat loader starts -->

    <!-- warning toast starts -->
    <div class="toast error static-toaster" *ngIf="showErrorToaster">
        <div class="outer-container">
            <img src="../../assets/images/svg/warning-icon.svg" alt="warning toaster">
        </div>
        <div class="inner-container">
            <h3>Error</h3>
            <p>{{showErrorMsg}}</p>
        </div>
        <a (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
    </div>
    <div class="toast success static-toaster" *ngIf="showSuccessToaster">
        <div class="outer-container">
            <img src="../../assets/images/svg/success-toaster-icon.svg" alt="warning toaster">
        </div>
        <div class="inner-container">
            <h3>Success</h3>
            <p>{{showSuccessMsg}}</p>
        </div>
        <a (click)="closeToaster()"><img src="../../assets/images/svg/Cancel.svg" alt="close alert" /></a>
    </div>
    <!-- warning toast ends -->

    <div class="main-search-results clearfix" [ngClass]="rightPanelAction ? 'expend-panel' : 'collapse-panel'">
        <!--- history left panel --->
        <div class="search-history-panel"
        *ngIf="currentRoute !== '/' && currentRoute !== '/cache-system' && currentRoute !== '/unauthorized' && currentRoute !== '/expired' && currentRoute !== '/plan-expired' && currentRoute !== '/search-results-view'">
            <app-left-history-panel></app-left-history-panel>
        </div>
        <!-- history left panel ends -->

        <div class="search-results-panel">
            <!-- Search category tabs -->
            <div class="search-category-tabs" (clickOutside)="closeDDownload()">
                <div class="row desktop-show">
                    <div class="col-lg-12">
                        <div class="new-version-header">
                            <div class="back-btn">
                                <span class="go-back" (click)="redirectToResult()"><img src="../../assets/images/svg/back-button.svg" alt="back button" title="Back to page"/><em>Back to Page</em></span>
                                <span class="left-history-icon" (click)="gridChange()"><img src="../../assets/images/svg/menu-icon.svg" alt="history menu" title="Show history" /></span>
                            </div>
                            <div class="new_vrs_search" (clickOutside)="closeHeaderSearch()">
                                <div class="vrs_search_section" (click)="resizerSearchHeight()"
                                    [ngClass]="resizeSearch ? 'textexpend' : 'textcollapse'">
                                    <div class="search-input">
                                        <div class="expend-search">
                                        <textarea [rows]="rows" #desktopSearch autocomplete="off"
                                            [placeholder]="placeholderText" spellcheck="true" id="hsearch"
                                            [value]="searchQuery" (input)="onChange($event,'search_query')" (focus)="onChange($event,'search_query')" (keydown)="preventEnterKey($event)" (clickOutside)="hideSuggestions()">
                                        </textarea>
                                        <button *ngIf="searchQuery" class="clear-btn" (click)="clearSearch()">
                                            ×
                                          </button>
                                        <button class="go-search" (click)="searchAssertion()">Go<em>!</em></button>
                                        </div>
                                        <div class="vrs-auto-suggitions inn-search-suggists" [ngClass]="resizeSearch ? 'expend' : 'collapse'" *ngIf="searchSuggestions.length > 0 && showSuggestions">
                                            <div class="auto-suggest-scroll">
                                                <ul class="suggestionscontainer">
                                                    <li *ngFor="let suggestion of searchSuggestions">
                                                        <div class="suggest-item clearfix">
                                                            <div class="suggested-text" (click)="setSearchQuery(suggestion.assertion_text)">
                                                                <span class="highlighted">{{suggestion.assertion_text}}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="down_arrow" style="display: none;">
                                        <a *ngIf="planType == 'Legit™ Premium'" href="javascript:void(0);">
                                            <img src="../../assets/images/svg/search-down-arrow.svg"
                                                alt="search arrow" />
                                        </a>
                                       <button class="go-search" (click)="searchAssertion()"><img src="../../assets/images/svg/search-icon.svg"
                                            alt="search arrow" /></button>
                                    </div>
                                </div>
                                <div class="vrs-auto-suggitions" [ngClass]="resizeSearch ? 'expend' : 'collapse'" style="display: none;">
                                    <div class="vrs-inn-auto-suggest">
                                        <ul>
                                            <li *ngFor="let asst of allAsertions" (click)="redirectToAssertion(asst)"><a
                                                    href="javascript:void(0);">{{asst}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="header-global-search" *ngIf="currentRoute == '/query'"
                        (clickOutside)="closeHeaderSearch()" [ngClass]="resizeSearch ? 'expend' : 'collapse'">
                        <div class="tqls-search">
                            <div class="search-input" (click)="resizerSearchHeight()">
                                <textarea [rows]="rows" #desktopSearch class="main-page-input gb-search-ipt"
                                    [value]="searchQuery" (input)="onChange($event, 'search_query')"
                                    autocomplete="off" [placeholder]="placeholderText" spellcheck="true"
                                    name="searchQuery" (clickOutside)="onOutsideClick()" [formControl]="email"
                                    (keydown)="preventEnterKey($event)" id="hsearch"></textarea>
                               
                                <div class="delete-query" *ngIf="searchQuery">
                                    <a (click)="removeSearch()">
                                        <img src="../../assets/images/svg/close-icon.svg" alt="close search" />
                                    </a>
                                </div>
                            </div>
                            <div class="main_auto_suggition">
                                <div class="auto-suggitions header"
                                    *ngIf="searchSuggestions.length > 0 && showSuggestions">
                                    <div class="auto-suggest-scroll">
                                        <ul>
                                            <li *ngFor="let suggestion of searchSuggestions">
                                                <div class="suggest-item clearfix">
                                                    <div class="suggested-text"
                                                        (click)="setSearchQuery(suggestion.assertion_text)"><span
                                                            class="highlighted">{{suggestion.assertion_text}}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="search-v-c" *ngIf="currentRoute !== '/'">
                            <ul>
                                <li><a class="search" (click)="createAnalysis()">
                                        <img src="../../assets/images/svg/search-icon.svg" alt="search result" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        </div> -->
                        </div>

                        <div class="category-tabs">
                            <ul>
                                <li><a (click)="openDialog('welcome')">Welcome Message</a></li>
                                <li><a (click)="openDialog('parts_of_speech')">Parts of Speech</a></li>
                                <!-- <li><a  (click)="openDialog('general_definition')">General Definitions</a></li> -->
                                <!-- <li><a  (click)="openDialog('general_analysis')">General Analysis</a></li> -->
                                <!-- <li><a  (click)="openDialog('entities')">Entities</a></li> -->
                                <!-- <li><a (click)="openDialog('character_counter')">Character counter</a></li> -->
                                <li *ngIf="planType!=='Legit™ Lite'">
                                    <a class="d-submenu" (click)="downloadDOption()">Download</a>
                                    <div class="download-menu" *ngIf="downloadOptions">
                                        <ul>
                                            <!-- <li><a (click)="downloadAsPDF()">Download PDF (.pdf)</a></li> -->
                                            <li><a (click)="downloadAsExcel()">Download Excel (.csv)</a></li>
                                            <!---- <li><a >Download word (.docx)</a></li> -->
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="mobile-view-tabs" (clickOutside)="closeMDownload()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="m-cate-tabs">
                                <div class="m-cate-title" [ngClass]="{'m-active' : showMCategory}">
                                    <a (click)="viewMTabs()">View More</a>
                                </div>
                                <div class="m-cate-menu" *ngIf="showMCategory">
                                    <ul>
                                        <li><a (click)="openDialog('welcome')">Welcome Message</a></li>
                                        <li><a (click)="openDialog('parts_of_speech')">Parts of Speech</a></li>
                                        <!-- <li><a  (click)="openDialog('general_definition')">General Definitions</a>
                                    </li> -->
                                        <!-- <li><a  (click)="openDialog('general_analysis')">General Analysis</a></li> -->
                                        <!-- <li><a  (click)="openDialog('entities')">Entities</a></li> -->
                                        <li><a (click)="openDialog('character_counter')">Character counter</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <!-- <div class="m-downloads">
                            <div class="m-cate-title" [ngClass]="{'m-active' : downloadPew}">
                                <a  class="d-submenu" (click)="downloadMOption()">Download</a>
                            </div>
                            <div class="download-mobile-options" *ngIf="downloadPew">
                                <ul>
                                    <li><a  (click)="downloadAsPDF()">Download PDF (.pdf)</a></li>
                                    <li><a >Download Excel (.csv)</a></li>
                                    <li><a >Download word (.docx)</a></li>
                                </ul>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="search-page-loader" *ngIf="isLoading">
                <div class="answer-loader">
                    <div class="left-ans-loader">
                        <div class="loader-container">
                            <div class="animated-background first"></div>
                            <div class="animated-background second"></div>
                            <div class="animated-background link"></div>
                        </div>
                    </div>
                    <div class="right-ans-loader">
                        <div class="loader-container">
                            <div class="animated-background mtitle"></div>
                            <div class="animated-background meter"></div>
                            <div class="animated-background mvalue"></div>
                        </div>
                    </div>
                </div>
            
                <div class="news_anns">
                    <div class="news__title">
                        <div class="loader-container">
                            <div class="animated-background"></div>
                        </div>
                    </div>
                    <div class="main-w-loader">
                        <div class="m-wl-loader">
                            <div class="loader-container">
                                <div class="nw__lft">
                                    <div class="img clearfix">
                                        <div class="animated-background im"></div>
                                        <div class="im-t">
                                            <div class="animated-background"></div>
                                            <div class="animated-background"></div>
                                        </div>
                                    </div>
                                    <div class="lft__desp">
                                        <div class="animated-background"></div>
                                        <div class="animated-background"></div>
                                    </div>
                                    <div class="lft__count">
                                        <div class="animated-background"></div>
                                    </div>
                                </div>
                                <div class="nw__rht">
                                    <div class="animated-background"></div>
                                </div>
                            </div>
                        </div>
                        <div class="m-wl-loader">
                            <div class="loader-container">
                                <div class="nw__lft">
                                    <div class="img clearfix">
                                        <div class="animated-background im"></div>
                                        <div class="im-t">
                                            <div class="animated-background"></div>
                                            <div class="animated-background"></div>
                                        </div>
                                    </div>
                                    <div class="lft__desp">
                                        <div class="animated-background"></div>
                                        <div class="animated-background"></div>
                                    </div>
                                    <div class="lft__count">
                                        <div class="animated-background"></div>
                                    </div>
                                </div>
                                <div class="nw__rht">
                                    <div class="animated-background"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="news_anns cc-loader">
                    <div class="news__title">
                        <div class="loader-container">
                            <div class="animated-background"></div>
                        </div>
                    </div>
                    <div class="main-w-loader">
                        <div class="m-wl-loader">
                            <div class="loader-container">
                                <div class="nw__lft">
                                    <div class="img clearfix">
                                        <div class="animated-background im"></div>
                                        <div class="im-t">
                                            <div class="animated-background"></div>
                                            <div class="animated-background"></div>
                                        </div>
                                    </div>
                                    <div class="lft__desp">
                                        <div class="animated-background"></div>
                                        <div class="animated-background"></div>
                                    </div>
                                    <div class="lft__count">
                                        <div class="animated-background"></div>
                                    </div>
                                </div>
                                <div class="nw__rht">
                                    <div class="animated-background"></div>
                                </div>
                            </div>
                        </div>
                        <div class="m-wl-loader">
                            <div class="loader-container">
                                <div class="nw__lft">
                                    <div class="img clearfix">
                                        <div class="animated-background im"></div>
                                        <div class="im-t">
                                            <div class="animated-background"></div>
                                            <div class="animated-background"></div>
                                        </div>
                                    </div>
                                    <div class="lft__desp">
                                        <div class="animated-background"></div>
                                        <div class="animated-background"></div>
                                    </div>
                                    <div class="lft__count">
                                        <div class="animated-background"></div>
                                    </div>
                                </div>
                                <div class="nw__rht">
                                    <div class="animated-background"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="display-content-section" *ngIf="!isLoading">
                <app-main-engine [searchResp]="searchResponse" [loading]="isLoading"></app-main-engine>
            </div>
        </div>

    </div>
</div>